module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Titil Studio","short_name":"Titil","start_url":"/","background_color":"#fff","theme_color":"#02aab0","display":"standalone","icon":"src/images/titil_logo_square.png","icons":[{"src":"/favicons/titil-logo-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/favicons/titil-logo-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/titil-logo-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/favicons/titil-logo-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/titil-logo-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/titil-logo-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/favicons/titil-logo-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/favicons/titil-logo-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7ec8b3ffa34888ddc53baeab67fb41da"},
    }]
